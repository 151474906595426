import {
	type LinksFunction,
	type HeadersFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { typedjson, useTypedLoaderData } from 'remix-typedjson'
import Blocks from '#app/components/blocks.tsx'
import CallToActionCard from '#app/components/callToAction.tsx'
import Header from '#app/components/header.tsx'
import sliderCSS from '#app/styles/swiper.css?url'
import { CRAFT_QUERY_KEYS, getPageDetails } from '#app/utils/graphql.server.ts'
import { seomaticMeta } from '#app/utils/misc'
import {
	buildBlockOrder,
	redirectIfPageDoesNotExist,
} from '#app/utils/misc.server.ts'
import { type Block } from '../../../types/graphql.page.ts'

export async function loader({ request }: LoaderFunctionArgs) {
	const redirect = await redirectIfPageDoesNotExist(request)
	if (redirect) throw redirect
	const url = new URL(request.url)
	const noCache = Object.entries(Object.fromEntries(url.searchParams)).filter(
		([key]) => CRAFT_QUERY_KEYS.includes(key),
	).length
	// Always include the SEO, header, footer, blocks and learship stuff for the regular pages
	// If it is a portfolio page, include the portfolio listing as well
	const data = await getPageDetails(request, 'generic-parent')

	const contentBlocks = buildBlockOrder(data.entry.blocks)

	return typedjson(
		{
			seo: data.seo,
			contentBlocks,
			subTitle: data.entry.subtitle || '',
			title: data.entry.title || '',
			footer: data.entry.footer && {
				...data.entry.footer[0],
				enabled: data.entry.footerEnabled,
			},
			leadershipList: data.leadershipList || [],
			historyList: data.historyList || [],
		},
		{
			headers: {
				'Cache-Control': noCache
					? 'max-age=0, s-maxage=0'
					: 'max-age=60, s-maxage=300',
			},
		},
	)
}

export const links: LinksFunction = () => {
	return [{ rel: 'stylesheet', href: sliderCSS }]
}

export let headers: HeadersFunction = ({ loaderHeaders }) => {
	return { 'Cache-Control': loaderHeaders.get('Cache-Control') || '' }
}

export const meta: MetaFunction<typeof loader> = ({ data }: { data: any }) => {
	return seomaticMeta(data.seo)
}

export default function PageRoute() {
	const { contentBlocks, title, subTitle, footer } =
		useTypedLoaderData<typeof loader>()

	return (
		<>
			<Header subtitle={subTitle} title={title} />
			<Blocks blocks={contentBlocks as Block[]} />
			{footer && footer.enabled && footer.link && (
				<CallToActionCard
					description={footer.description || ''}
					title={footer.title || ''}
					link={footer.link || ''}
				/>
			)}
		</>
	)
}
